<template>
  <div class="stage">
    <Search :searchOptions="options" @search="getSearch">
      <div class="btns">
        <el-button class="btn" type="primary" @click="modalShow"
          >新增</el-button
        >
      </div>
    </Search>
    <div class="stage_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
        ><template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)">查看</el-button
            ><el-button type="text" @click="look('edit', scope)">编辑</el-button
            ><el-button type="text" style="color: red" @click="del(scope)"
              >删除</el-button
            >
          </div>
        </template></Table
      >
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      title="服务信息"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :disabled="mode == 'look' ? true : false"
      >
        <el-form-item label="服务名称" prop="serviceName">
          <el-input
            v-model="ruleForm.serviceName"
            placeholder="请输入服务名称"
          />
        </el-form-item>
        <el-form-item label="服务方" prop="serviceCompany">
          <el-input
            v-model="ruleForm.serviceCompany"
            placeholder="请输入服务方"
          />
        </el-form-item>
        <el-form-item label="服务分类" prop="serviceTypeId">
          <el-select
            v-model="ruleForm.serviceTypeId"
            placeholder="请选择服务分类"
            style="width: 240px"
          >
            <el-option
              v-for="item in serviceoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商家地址" prop="address">
          <el-input v-model="ruleForm.address" placeholder="请输入商家地址" />
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            placeholder="请输入联系电话"
          />
        </el-form-item>
        <el-form-item label="商家封面图(最多一张)" prop="picture">
          <Upload
            :fileList="fileList"
            :limit="1"
            @updateImage="updateImage"
          ></Upload>
        </el-form-item>
        <el-form-item label="商家介绍图(最多六张)" prop="picture">
          <Upload
            :fileList="fileLists"
            :limit="6"
            @updateImage="updateImages"
          ></Upload>
        </el-form-item>
        <el-form-item label="商家介绍" prop="content">
          <el-input
            v-model="ruleForm.content"
            :rows="5"
            type="textarea"
            maxlength="300"
            show-word-limit
            placeholder="请输入商家介绍"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
import Upload from "../../components/Upload.vue";
export default {
  components: {
    Search,
    Table,
    Upload,
  },
  data() {
    return {
      dialogVisible: false,
      mode: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "input",
          placeholder: "服务名称",
          prop: "shopName",
        },
      ],
      tableData: [],
      tableOptions: [
        {
          label: "服务名称",
          prop: "serviceName",
        },
        {
          label: "类型",
          prop: "serviceTypeId",
        },
        {
          label: "商家地址",
          prop: "address",
        },
        {
          label: "服务方",
          prop: "serviceCompany",
        },
        {
          label: "联系电话",
          prop: "contactPhone",
        },
      ],
      ruleForm: {
        serviceName: "",
        serviceCompany: "",
        serviceTypeId: "",
        address: "",
        contactPhone: "",
        coverPicture: "",
        introducePicture: [],
        content: "",
      },
      rules: {
        serviceName: [
          { required: true, message: "请输入服务名称", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        serviceCompany: [
          { required: true, message: "请输入服务方", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        serviceTypeId: [
          { required: true, message: "请选择服务分类", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入商家地址", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        coverPicture: [
          { required: true, message: "请上传商家封面图", trigger: "change" },
        ],
        introducePicture: [
          { required: true, message: "请上传商家介绍图", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入商家介绍", trigger: "blur" },
        ],
      },
      fileList: [],
      fileLists: [],
      serviceoptions: [],
      serviceCompany: {},
    };
  },
  mounted() {
    this.loadServerCompany();
  },
  methods: {
    look(type, row) {
      this.mode = type;
      this.ruleForm = Object.assign(
        {},
        {
          ...row,
          introducePicture: row.introducePicture ? row.introducePicture : [],
          coverPicture: row.coverPicture ? row.coverPicture : "",
        }
      );
      this.fileList = row.coverPicture ? [{ url: row.coverPicture }] : [];
      this.fileLists = row.introducePicture
        ? row.introducePicture.split(",").map((item) => {
            return {
              url: item,
            };
          })
        : [];
      this.dialogVisible = true;
    },
    del(row) {
      this.$confirm("此操作将删除该信息, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { flag } = await this.$del(
            `/xng/backed/lifeService/delLifeService?id=${row.id}`
          );
          if (flag) {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSearch(val) {
      this.page = {
        pageNum: 1,
        pageSize: 10,
        ...val,
      };
      this.loadData();
    },
    // 获取服务商
    async loadServerCompany() {
      const { flag, data } = await this.$get(
        "/xng/backed/lifeService/selectPageListTypeByName"
      );
      if (flag) {
        this.serviceoptions = data.rows?.map((item) => {
          this.serviceCompany[item.id] = item.typeName;
          return {
            label: item.typeName,
            value: item.id,
          };
        });
        this.tableOptions = this.tableOptions.map((item) => {
          if (item.prop == "serviceTypeId") {
            return {
              ...item,
              enum: this.serviceCompany,
            };
          }
          return item;
        });
        this.loadData();
      }
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/lifeService/selectPageListByName",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows.map((item) => {
          return {
            ...item,
            url: item.picture,
          };
        });
      }
    },
    modalShow() {
      this.dialogVisible = true;
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.ruleForm = {};
          this.mode = "add";
          this.fileList = [];
          this.fileLists = [];
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.ruleForm);
          if (this.ruleForm.id) {
            const { flag } = await this.$post(
              "/xng/backed/lifeService/updateLifeService",
              this.ruleForm
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "编辑成功",
                type: "success",
              });
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            const { flag } = await this.$post(
              "/xng/backed/lifeService/addLifeService",
              this.ruleForm
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
            } else {
              this.$message.error("新增失败");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    updateImage(val) {
      this.fileList = val;
      this.ruleForm.coverPicture = val.length ? val[0].url : "";
    },
    updateImages(val) {
      this.fileLists = val;
      val.map((item, index) => {
        if (index) {
          this.ruleForm.introducePicture =
            this.ruleForm.introducePicture + "," + item.url;
        } else {
          this.ruleForm.introducePicture = item.url;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.stage {
  width: 100%;
  .btns {
    padding: 0 10px;
  }
  .stage_content {
    width: 100%;
  }
}
</style>
