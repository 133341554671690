<template>
  <el-upload
    action="#"
    ref="upload"
    :limit="limit"
    list-type="picture-card"
    :on-remove="handleRemove"
    :http-request="uploadSectionFile"
    :file-list="fileList"
  >
    <i slot="default" class="el-icon-plus"></i>
  </el-upload>
</template>

<script>
export default {
  props: {
    fileList: [],
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 图片上传
    async uploadSectionFile(params) {
      const formData = new FormData();
      console.log(formData);

      formData.append("files", params.file);
      const res = await this.$post("/clue/upLoad", formData);
      if (res.flag) {
        let arr = this.fileList;
        arr.push({ url: res.data[0] });
        // this.ruleForm.picture.push(res.data[0]);
        // this.fileList.push({ url: res.data[0] });
        this.$emit("updateImage", arr);
      }
    },
    handleRemove(file, fileList) {
      let arr = this.fileList;
      arr.map((item, index) => {
        if (item.url == file.url) {
          arr.splice(index, 1);
          this.$emit("updateImage", arr);
        }
      });
    },
  },
};
</script>

<style></style>
